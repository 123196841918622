import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/utils/seo';
import Logo from '../components/ui/image';
import tw from 'twin.macro';

const Container = tw.section`
  container mx-auto max-w-full h-screen
`;

const Section = tw.div`
  flex flex-col justify-center content-center h-screen
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Zunderwerk • Light Design & Light Operator from Germany" />
    <Container>
      <Section>
        <Logo />
      </Section>
    </Container>
  </Layout >
);

export default IndexPage;
