import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Image = () => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "images/logo_zunderwerk_black_orange.png" }) {
        childImageSharp {
          fixed(height: 512, quality: 100) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `);

  return (<Img className="mx-auto" alt="Logo" title="Logo" fixed={logo.childImageSharp.fixed} />)
}

export default Image
